.TransferItem {
    height: 20vh;

    background-color: rgba(0, 0, 0, 0.5);
    background-position: center center;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;
}