.UploadScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    
    flex-grow: 1;
}

.UploadContainer {
    display: flex;
    flex-direction: column;
    
    align-items: center;
}

.UploadButton {
    display: flex;
    align-items: center;

    margin-top: 9px;
    
    background-color: var(--button-color);
    padding: 12px;
}

.UploadButton:before {
    content: "";
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
    
    height: 16px;
    width: 16px;
    margin-right: 3px;
    filter: invert(1);
}

.UploadButton:hover {
    background-color: var(--button-hover);
}

.UploadButton:active {
    background-color: var(--button-active);
}

.Dropping {
    border: 10px solid rgb(0, 200, 255);
}

.FilePicker {
    display: none;
}